body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo_SY {
  margin-top: 15px;
  margin-bottom: 15px;
  /* margin-left: 150px; */
  width: 250px;
}

.bg_nav {
  background-color: white;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.382);
  height: 90px;
}

.incollaFooter {
  margin-top: auto;
}
.myFooter {
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.382) !important;
}

.myFooterImg {
  width: 150px !important;
}

.colonnaFooter {
  /* max-width: 400px;
  min-width: 400px; */
  text-align: center;
}

.centraColFooter {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

/* .diofa {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
} */

.margin_top {
  margin-top: 150px;
}

.alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.alert-message {
  background-color: white;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.success {
  color: green;
}

.error {
  color: red;
}

.new-blog-container {
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 100px;
}

.new-blog-content {
  height: 400px;
  margin-bottom: 80px;
}

/* ------------------------------------------------------------------------- 
LOGIN */

.login {
  max-width: 500px !important;
  background: rgb(62, 62, 62);
  background: linear-gradient(87deg, #3e3e3e 0%, #8e8e8e 100%);
  border-radius: 20px;
  border: solid black 3px;
}

.prova {
  background-color: transparent !important;
  border-color: rgb(207, 207, 207) !important;
}

.prova:hover {
  background-color: rgba(255, 255, 255, 0.316) !important;
  border-color: azure !important;
}

.prova:active {
  background-color: rgba(255, 255, 255, 0.681) !important;
  border-color: azure !important;
}

.form-control:focus {
  border: none !important;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.423) !important;
}

/* ------------------------------------------------------------------------- 
NAVBAR */

.blog-navbar-brand {
  width: 180px;
}

/*  */

.proviamo {
  display: flex !important;
  justify-content: space-between !important;
}

.img-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.togli-bordo {
  border-style: none;
  border: 0 !important;
  color: rgb(97, 97, 97) !important;
}

.togli-bordo:focus {
  border-style: none;
  border: 0 !important;
  /* color: rgb(60, 128, 255) !important; */
}

.togli-bordo:active {
  border-style: none;
  border: 0 !important;
  /* color: rgb(60, 128, 255) !important; */
}

.togli-bordo::after {
  border-style: none;
  border: 0 !important;
  /* color: rgb(60, 128, 255) !important; */
}

.clicked {
  color: rgb(255, 60, 60) !important;
}

.selection:hover {
  color: #ffffff !important;
  background: rgb(213, 199, 1);
  background: linear-gradient(87deg, rgb(255, 187, 0) 0%, rgb(246, 48, 4) 100%);
}

.selection:active {
  color: #ffffff !important;
  background: rgb(213, 199, 1);
  background: linear-gradient(87deg, rgb(255, 187, 0) 0%, rgb(246, 48, 4) 100%);
}

.nav-a {
  text-decoration: none;
  color: rgb(238, 238, 238);
  font-weight: bold;
  font-size: large;
  /*transition: font-size 0.3s ease;*/
  transition: transform 0.3s ease;
  display: inline-block;
  /* Importante per gestire lo spazio */
}

.nav-a:hover {
  /* font-size: 26px; */
  transform: scale(1.2);
  transform-origin: center;
}

.colonnaDXnav {
  width: 250px;
}

.tag-ul {
  list-style: none;
}

.game_post_link {
  width: 100px;
}

.ms-mod-nav {
  margin-left: 100px;
}

/* ------------------------------------------------------------------------- 
GESTIONALE */

.p-account-img {
  width: 600px;
  height: 600px;
  border-radius: 10px;
  object-fit: cover;
  object-position: 0% 0%;
}

.p-account-details {
  margin-top: 200px;
  text-align: center;
}

.img-postmap {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.p-account-box-singlePost {
  white-space: nowrap;
  /* Impedisce il wrapping del testo */
  overflow: hidden;
  /* Nasconde il testo che supera i limiti del contenitore */
  text-overflow: ellipsis;
  text-decoration: none;
  color: black;
  padding: 5px 20px 5px 5px;
  border: solid 2px rgba(168, 168, 168, 0.427);
  border-radius: 30px;
}

.gestionale-bg {
  background-color: rgba(255, 255, 255, 0.493);
  border-radius: 20px;
  padding: 10px;
  margin: 20px;
}

/* ------------------------------------------------------------------------- 
CHECK BOX PER AGGIUNGERE I CORSI */

.checkBoxCorsi {
  border: black solid 1px;
  border-radius: 20px;
  background-color: rgba(105, 105, 105, 0.11);
  width: 500px;
  padding: 15px;
  /*
  margin-left: auto;
  margin-right: auto; */
}

.margin_T80 {
  margin-top: 80px;
}

.margin_T90 {
  margin-top: 90px;
}

.margin_T100 {
  margin-top: 100px;
}

.margin_T110 {
  margin-top: 110px;
}

.margin_T120 {
  margin-top: 120px;
}

.margin_T150 {
  margin-top: 150px;
}

.margin_T180 {
  margin-top: 180px;
}

.margin_S65 {
  margin-left: 65px;
}

.margin_D150 {
  margin-right: 150px;
}

.margin_YAUTO {
  margin-left: auto;
  margin-right: auto;
}

.box_string {
  width: 500px;
}

.imgBox-cover {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

.imgBox-cover-home {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.clear_ul {
  list-style-type: none;
}
.clear_link_black {
  text-decoration: none;
  color: rgb(0, 0, 0);
}
.clear_link {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.clear_link:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.clear_link_footer {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.clear_link_footer:hover {
  color: red;
}

.link_youtube {
  color: red;
  transition: color 0.3s;
}

.link_youtube:hover {
  color: rgb(190, 0, 0);
}

.link_instagram {
  color: rgb(0, 0, 0);
}

/* .link_Nav {
  text-decoration: none;
  color: black;
}

.link_Nav:hover {
  color: blue;
  padding-bottom: 5px;
  border-bottom: solid 5px blue;
} */

.margin_fcolonnaCentr {
  margin-top: 11px;
}

.link_Nav {
  position: relative;
  text-decoration: none;
  color: rgb(97, 97, 97) !important;
  transition: color 0.3s;
}

.link_Nav::after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px; /* Altezza della barra */
  bottom: 0px; /* Posizione della barra */
  left: 50%;
  background-color: rgb(101, 155, 255) !important;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out; /* Aggiungi transizione per 'transform' */
  transform: translateX(-50%);
}

.link_Nav:hover {
  color: rgb(60, 128, 255) !important;
}

.link_Nav:hover::after {
  width: 100%; /* Allarga la barra */
  transform: translateX(-50%) scaleX(1); /* Allarga la barra dal centro */
}

.link_Nav::after {
  transform: translateX(-50%) scaleX(0); /* Contrai la barra al centro */
}

.link_Nav_Mobile {
  position: relative;
  text-decoration: none;
  color: rgb(97, 97, 97);
  transition: color 0.3s;
  font-weight: 600 !important;
}

.text_Color_Grigio {
  color: rgb(97, 97, 97) !important;
}

.togli-p-m {
  margin: 0 !important;
  padding: 0 !important;
}

.max {
  margin-top: 10px !important;
}

.sidebar {
  width: 350px;
  background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  );
  border-right: 5px black solid;
}

/* ------------------------------------------------------------------------- 
CARD ITEM */

.blog-cover {
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: 0% 0%;
}

.blog-card {
  width: 200px;
}

.blog-link {
  text-decoration: none;
  color: inherit;
}

.card {
  border-radius: 30px !important;
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.card a {
  display: block;
  text-decoration: none; /* Rimuovi la sottolineatura del link, se necessario */
}

.card img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.5s ease-in-out;
}

.card:hover img {
  filter: brightness(70%);
}

.card2 {
  border-radius: 30px !important;

  width: 30rem;
  height: 100px;
}

.card2 a {
  display: block;
  text-decoration: none; /* Rimuovi la sottolineatura del link, se necessario */
}

.card2 img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.5s ease-in-out;
}

.card2:hover img {
  filter: brightness(70%);
}

.card2 h4 {
  color: black !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* sfondo scuro con opacità 0.5 */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .overlay {
  opacity: 1;
}

.fade-in {
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .fade-in {
  opacity: 1;
}

.prove {
  border-radius: 20px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.071);
}

.prove2 {
  border-radius: 20px;
  margin: 0px 15px 10px 15px;
  padding: 0px 20px 0px 20px;
  background-color: rgba(0, 0, 0, 0.071);
}

.prove3 {
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 15px 0px;
  background-color: rgba(0, 0, 0, 0.071);
}

.prove4 {
  border-radius: 10px;
  margin: 0px 0px 15px 0px;
  padding: 0px 10px 0px 10px;
  background-color: rgba(0, 0, 0, 0.071);
}

/* .toggleSI {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.toggleNO {
  opacity: 0;
  pointer-events: none;
  height: 0;
  overflow: hidden;
} */

.toggleSI {
  opacity: 1;
  max-height: 500px;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 1s ease;
}

.toggleNO {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.3s ease;
}

.asd1 {
  padding-left: 10px;
}

.prove6 {
  background-color: rgba(0, 0, 0, 0.071);
}

.asd2 {
  padding-left: 13px;
}

.prove7 {
  background-color: rgba(0, 0, 0, 0.071);
}

.asd3 {
  padding-left: 30px;
}

.prove8 {
  background-color: rgba(0, 0, 0, 0.071);
}

.asd4 {
  padding-left: 40px;
  padding-right: 15px;
}
.check_RAMI_2 {
  margin-left: 15px;
}
.check_RAMI_3 {
  margin-left: 30px;
}
.check_RAMI_4 {
  margin-left: 45px;
}

.asd1:hover {
  background-color: rgba(255, 255, 255, 0.505);
}
.asd2:hover {
  background-color: rgba(255, 255, 255, 0.505);
}
.asd3:hover {
  background-color: rgba(255, 255, 255, 0.505);
}
.asd4:hover {
  background-color: rgba(255, 255, 255, 0.505);
}

.ramo4Mod {
  margin: 0 !important;
  padding-top: 5px;
  padding-bottom: 10px;
}

.font_percorso {
  height: 30px;
  font-size: 24px;
  font-weight: bold;
}

.check_purchase {
  font-size: 24px;
  font-weight: bold;
}

.selected {
  color: aqua;
}

.puntatore:hover {
  cursor: pointer;
}

.my_input {
  height: 50px;
  padding-left: 20px;
  border-radius: 10px;
  border: solid 2px rgb(66, 66, 66);
  box-shadow: 2px 2px 2px rgb(101, 101, 101);
  transition: box-shadow 0.1s ease;
}

.my_input:hover {
  border: solid 2px rgb(137, 137, 137);
  box-shadow: 5px 5px 5px rgb(85, 85, 85);
}

.my_input:focus {
  border: solid 2px rgb(28, 28, 28);
  box-shadow: 5px 5px 5px rgb(42, 42, 42);
  outline: none;
}

.my_input2 {
  height: 50px;
  width: 50%;
  padding-left: 20px;
  border-radius: 10px;
  border: solid 2px rgb(66, 66, 66);
  box-shadow: 2px 2px 2px rgb(101, 101, 101);
  transition: box-shadow 0.1s ease;
}

.my_input2:hover {
  border: solid 2px rgb(137, 137, 137);
  box-shadow: 5px 5px 5px rgb(85, 85, 85);
}

.my_input2:focus {
  border: solid 2px rgb(28, 28, 28);
  box-shadow: 5px 5px 5px rgb(42, 42, 42);
  outline: none;
}

.imgWIP {
  width: 100px;
}

.pagination {
  justify-content: center !important;
  display: flex !important;
  padding-left: 0 !important;
  list-style: none !important;
  margin: 0 !important;
}

.page-item .page-link {
  position: relative !important;
  display: block !important;
  margin: 0 2px !important;
  min-height: 40px !important;
  min-width: 40px !important;
  border-radius: 50px !important;
  text-align: center !important;
  line-height: 25px !important;
  color: black !important;
  text-decoration: none !important;
}

.page-item:last-child .page-link {
  border-radius: 50% !important;
}

.page-item:first-child .page-link {
  border-radius: 50% !important;
}

.page-item {
  min-height: 10px !important;
  min-width: 10px !important;
}

.page-item a.page-link:hover {
  background: rgb(255, 8, 0) !important;
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 0.56) 0%,
    rgba(255, 162, 0, 0.56) 100%
  ) !important;
  color: #ffffff !important;
}

.page-item.active .page-link {
  font-weight: 700 !important;
  color: #ffffff !important;
  background: rgb(255, 8, 0) !important;
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  ) !important;
  border: none !important;
  margin: 0 10px 0 10px !important;
}

.page-item.disabled .page-link {
  color: #7d6f6c !important;
  pointer-events: none !important;
  cursor: auto !important;
}

.pagination .active > .page-link {
  z-index: 0 !important;
}
.active > .page-link,
.page-link.active {
  z-index: 0 !important;
}
.carrello_badge {
  top: 5px;
  left: 50px;
}

/*--------------------------*/
.car_box {
  border: solid black 5px;
  border-radius: 20px;
  margin: 4rem;
}

.listaCarrello {
  width: 100%;
}

.box_sottoCorsi {
  height: auto;
  width: auto;
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  );
  border: solid 2px black;
  box-shadow: 5px 5px 5px black;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box_sottoCorsi:hover {
  transform: scale(1.1) !important;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.text_percorso {
  font-size: 14px;
}

.text_navLink {
  font-size: 18px;
  font-weight: 600 !important;
  margin-top: 13px;
  margin-bottom: 13px;
}

.text_navLink_mobile {
  font-size: 18px;
  font-weight: 600 !important;
  margin-bottom: 10;
}

.hr_nav_mobile {
  margin-bottom: 12px;
}

.text_m1 {
  margin-bottom: 1px;
}

.text_carrello {
  font-size: 12px;
}

.text_prezzo {
  font-size: 20px;
}

.text_ramo1 {
  font-size: 23px;
  font-weight: bold;
}
.text_ramo2 {
  font-size: 20px;
  font-weight: bold;
}
.text_ramo3 {
  font-size: 17px;
  font-weight: bold;
}
.text_ramo4 {
  font-size: 14px;
  font-weight: bold;
}
.text_slash {
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  margin-top: 2px;
  margin-right: 2px;
  padding: 0;
}

.text_percorso {
  height: 20px;
}

.text_grey {
  color: #8e8e8e;
}

.textDetAcc {
  font-size: 19px;
  padding-bottom: 10px;
}

/* .cta {
  --shadowColor: 187 60% 40%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 60%;
  height: 200px;
  box-shadow: 0.65rem 0.65rem 0 rgba(105, 105, 105, 0.253);
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.15rem solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta p {
  color: black;
}

.cta img {
  width: 200px;
  object-fit: cover;
  outline: 0.15rem solid;
}

.cta__text-column {
  padding: min(2rem, 5vw) min(2rem, 5vw) min(2.5rem, 5vw);
  flex: 1 0 50%;
}

.cta__text-column > * + * {
  margin: min(1.5rem, 2.5vw) 0 0 0;
}

.cta h4 {
  color: rgb(0, 0, 0) !important;
}


.cta:hover {
  transform: scale(1.1) !important;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
} */

.cta {
  /* --shadowColor: 187 60% 40%; */
  display: flex;
  flex-wrap: wrap;
  /* background: hsl(32, 81%, 55%); */
  /* background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  ); */
  background-color: white;
  /* max-width: 50rem; */
  /* width: 100%; */
  width: 60%;
  min-height: 180px;
  max-height: 180px;
  box-shadow: 0.65rem 0.65rem 0 rgba(105, 105, 105, 0.253);
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.15rem solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta_mobile {
  /* --shadowColor: 187 60% 40%; */
  display: flex;
  flex-direction: column;
  /* background: hsl(32, 81%, 55%); */
  /* background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  ); */
  background-color: white;
  /* max-width: 50rem; */
  /* width: 100%; */
  width: 80%;
  min-height: 250px;
  max-height: 250px;
  box-shadow: 0.65rem 0.65rem 0 rgba(105, 105, 105, 0.253);
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.15rem solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta_mobile img {
  /* width: 200px; */
  min-height: 120px;
  max-height: 120px;
  width: 100%;
  object-fit: cover;
  outline: 0.15rem solid;
}

.cta__text-column_mobile {
  /* padding: min(2rem, 5vw) min(2rem, 5vw) min(2.5rem, 5vw);
  padding-top: 5px !important; */
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 0 50%;
}

.hr_cardVideo_mobile {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.cta p {
  color: black;
}

.cta img {
  /* width: 200px; */
  min-height: 180px;
  max-height: 180px;
  width: 200px;
  object-fit: cover;
  outline: 0.15rem solid;
}

.cta__text-column {
  padding: min(2rem, 5vw) min(2rem, 5vw) min(2.5rem, 5vw);
  flex: 1 0 50%;
}

.cta__text-column > * + * {
  margin: min(1.5rem, 2.5vw) 0 0 0;
}

.cta h4 {
  color: rgb(0, 0, 0) !important;
}

.cta:hover {
  transform: scale(1.1) !important;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.ctaEllisse {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctaDivEllipsis {
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */

  max-height: 25px;
}

/* ---------------------------------------------------

.cta2 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  width: 30%;
  height: 50px;
  box-shadow: 0.65rem 0.65rem 0 rgba(105, 105, 105, 0.253);
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.15rem solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta2__text-column {
  flex: 1 0 50%;
}

.cta2 p {
  color: black;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.cta2 a {
  width: 100%;
}

.cta2 img {
  max-width: 60px;
  min-width: 60px;
  height: 50px;
  object-fit: cover;
  outline: 0.15rem solid black;
}

.cta2 h4 {
  color: rgb(0, 0, 0) !important;
}

.cta2:hover {
  transform: scale(1.1) !important;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.cta2_div {
  outline: 0.15rem solid black;
  height: 50px;
  max-width: 60px;
  min-width: 60px;
}

.cta2_pulsanti {
  margin-top: 5px;
}

.cta2Ellisse {
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap; 
}

.cta2DivEllipsis {
  overflow: hidden;
  max-height: 25px;
} */

/*---------------------------------------------------*/

.cta2 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  /* background: hsl(32, 81%, 55%); */
  /* background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  ); */
  /* max-width: 50rem; */
  /* width: 100%; */
  /* width: 30%; */
  width: 30%;
  height: 50px;
  box-shadow: 0.65rem 0.65rem 0 rgba(105, 105, 105, 0.253);
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.15rem solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta2_mobile {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  /* background: hsl(32, 81%, 55%); */
  /* background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  ); */
  /* max-width: 50rem; */
  /* width: 100%; */
  /* width: 30%; */
  width: 70%;
  height: 50px;
  box-shadow: 0.65rem 0.65rem 0 rgba(105, 105, 105, 0.253);
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.15rem solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.cta2_mobile img {
  /* width: 25%; */
  max-width: 60px;
  min-width: 60px;
  height: 50px;
  object-fit: cover;
  outline: 0.15rem solid black;
}

.cta2__text-column {
  flex: 1 0 50%;
}

.cta2 p {
  color: black;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.cta2 img {
  /* width: 25%; */
  max-width: 60px;
  min-width: 60px;
  height: 50px;
  object-fit: cover;
  outline: 0.15rem solid black;
}

.cta2 h4 {
  color: rgb(0, 0, 0) !important;
}

.cta2:hover {
  transform: scale(1.1) !important;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.cta2_div {
  outline: 0.15rem solid black;
  height: 50px;
  max-width: 60px;
  min-width: 60px;
}

.cta2_pulsanti {
  /* outline: 0.15rem solid black; */
  margin-top: 10px;
}

.cta2Ellisse {
  overflow: hidden; /* Nascondere il testo che esce dai limiti dell'elemento */
  text-overflow: ellipsis; /* Aggiungere i puntini di sospensione */
  white-space: nowrap; /* Impedire al testo di andare su una nuova riga */
}

.cta2DivEllipsis {
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  max-height: 25px;
}

/*-------------------HAMBURGER---------------*/
/* .hamburger-react {
  z-index: 10;
  left: 0px;
  margin-top: 100px;
  position: fixed !important;
  width: 48px !important;
} */
.hamburger-react {
  z-index: 99 !important;
  width: 48px !important;
}

.hamburger-container-1 .hamburger-react {
  left: 0px;
  margin-top: 100px;
  position: fixed !important;
}

.hamburger-container-2 .hamburger-react {
  margin-top: 0px;
}

.menu-2 {
  position: fixed;
  margin-top: 90px;
  top: 0;
  /* left: 0px; */
  right: -100%;
  height: 100vh;
  /* width: 350px; */
  width: 100%;
  background: rgb(224, 224, 224);

  /* border-right: 5px black solid; */

  display: flex;
  flex-direction: column;
  transition: right 0.3s ease;
  z-index: 11;
}

.menu-2.open2 {
  right: 0;
}

.carrello_badge2 {
  top: 14px;
  left: 100px;
}

.menu {
  position: fixed;
  top: 0;
  /* left: 0px; */
  left: -100%;
  width: 250px;
  height: 100vh;
  max-height: 100vh;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: left 0.3s ease;
  z-index: 1;
}

.menu.open {
  left: 0;
}

.menu a {
  padding: 20px;
  text-decoration: none;
  color: black;
  text-align: center;
}

.menu a:hover {
  background-color: #ddd;
}

.menuSidebar {
  position: fixed;
  margin-top: 90px;
  top: 0;
  /* left: 0px; */
  left: -100%;
  height: 100vh;
  width: 350px;
  background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  );
  border-right: 5px black solid;

  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: left 0.3s ease;
  z-index: 1;
}

.menuSidebar.open1 {
  left: 0;
}

.menuSidebar a {
  /* padding: 20px; */
  text-decoration: none;
  text-align: center;
}

.contenutoSidebar {
  padding-top: 75px;
  position: absolute;
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
}

.contenutoSidebar::-webkit-scrollbar {
  width: 5px; /* Larghezza della barra di scorrimento */
}

.contenutoSidebar::-webkit-scrollbar-thumb {
  background-color: #fcfcfc; /* Colore del pulsante di scorrimento */
}

.contenutoSidebar::-webkit-scrollbar-thumb:hover {
  background-color: #dfdfdf; /* Colore del pulsante di scorrimento al passaggio del mouse */
}

.contenutoSidebar::-webkit-scrollbar-track {
  background: rgb(255, 8, 0);
  background: linear-gradient(
    183deg,
    rgba(255, 8, 0, 1) 0%,
    rgba(255, 162, 0, 1) 100%
  ); /* Colore della traccia della barra di scorrimento */
}

.contenutoSidebar::-webkit-scrollbar-track:hover {
  background-color: #cacaca; /* Colore della traccia al passaggio del mouse */
}

/* .imgCard {
  border-radius: 0px !important;
  border-bottom: solid black 2px;
  object-fit: cover;
}
.cardCustom {
  border: solid black 2px !important;
  width: 18rem !important;
  height: 18rem !important;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
} */
.imgCard {
  border-radius: 0px !important;
  border-bottom: solid black 2px;
  object-fit: cover;
  width: 100%;
  height: 135px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imgCardLast4 {
  border-radius: 0px !important;
  border-bottom: solid black 2px;
  object-fit: cover;
  width: 100%;
  height: 135px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .cardCustom {
  border: solid black 2px !important;
  width: 18rem !important;
  height: auto !important;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.cardCustom {
  border: solid black 2px !important;
  width: 18rem !important;
  height: auto !important;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardCustom:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.cardCustom .card-title {
  transition: color 0.3s ease; /* Transizione per il colore del titolo */
}

.cardCustom:hover .card-title {
  color: red; /* Cambia il colore del titolo in rosso */
}

.poi {
  border-radius: 20px !important;
}

.imgCarosello {
  object-fit: fill !important;
}

.cardCustomLast4 {
  border-radius: 10px !important;
  border: solid black 2px !important;
  width: 12rem !important;
  height: 12rem !important;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerLogin {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.colonna-sinistra {
  width: 70%;
  /* height: 100%; */
  /* background-color: red;
  background-image: url("./assets/background-3.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed; */
}
.tre {
  margin-top: auto;
  margin-bottom: auto;
}
.colonna-destra {
  width: 30%;
  height: 100vh;
  /* margin-top: 90px; */
  padding: 20px;
  /* background-color: #3e3e3e; */
  background-image: url("./assets/background-glass2.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  /* border-left: 5px solid white; */
  /* box-shadow: 0px 0px 0px #ffffff, -5px -1px 5px #ffffff53; */

  /* box-shadow: -8px -10px 10px -10px rgba(0, 0, 0, 0.2) inset; */
  box-shadow: 0px -10px 10px -10px rgba(0, 0, 0, 0.2) inset;
}

.subLinksContainer {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

.subLinksContainer.show {
  max-height: 600px; /* Aumenta l'altezza massima per contenere entrambi i link */
  opacity: 1;
}

.trewq {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.trewq:hover .dropdown-content {
  display: block;
}

/* .boxImmagini img {
  width: 200px;
} */

.circleImgLink {
  width: 200px;
  border-radius: 50%;
  transition: transform 0.4s ease, box-shadow 0.4s ease, border 0.4s ease;
  cursor: pointer;
}

.circleImgLink:hover {
  transform: scale(1.1);
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.493);
}

.circleImg {
  width: 200px;
  border-radius: 50%;
}

.mainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hover_red:hover {
  color: red;
}

.w_70 {
  width: 70%;
}

.m_ {
  margin-left: 100px;
  margin-right: 100px;
}

.circle-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Questo rende il checkbox circolare */
  border: 2px solid #000; /* Colore del bordo */
  appearance: none; /* Nasconde l'aspetto predefinito del checkbox */
  -webkit-appearance: none;
  -moz-appearance: none;
}

.circle-checkbox input[type="checkbox"]:checked {
  background-color: #000000; /* Colore di riempimento quando selezionato */
  border: 2px solid #000; /* Mantiene il bordo visibile */
}

.circle-checkbox input[type="checkbox"]:focus {
  outline: none; /* Rimuove il contorno di focus */
  box-shadow: none; /* Rimuove eventuali ombre che potrebbero apparire */
}

.bannerCorsiLive {
  cursor: pointer;
  height: 130px;
  width: 1000px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 20px;
}

.bannerCorsiLive:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.bannerPubbl {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 20px;
}

.bannerPubbl:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.493);
}

.altezzaTitoloCarosello {
  height: 70px;
}

.box-descrizione {
  border-style: solid;
  border-radius: 20px;
}

.text_piccolo {
  font-size: 11px;
}

.provafocus:focus {
  border: #ffffff !important;
  box-shadow: 5px 5px 5px blue;
}

.custom-focus .form-control:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.5) !important; /* Cambia colore e trasparenza */
  border-color: #007bff !important; /* Cambia anche il colore del bordo */
}
