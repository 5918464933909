.quill {
    .ql-toolbar {
        border-color: #dee2e6;
        border-radius: 10px 10px 0px 0px;
    }

    .ql-container {
        border-color: #dee2e6;
        border-radius: 0px 0px 10px 10px;
        min-height: 300px;
    }
}